import friabilometro1 from '../friabilometro/friabilometro1.jpg'
import friabilometro2 from '../friabilometro/friabilometro2.jpg'
import friabilometro3 from '../friabilometro/friabilometro3.jpg'

const imagefriabilometro = [
    friabilometro3,
    friabilometro2,
    friabilometro1,
]

export default imagefriabilometro