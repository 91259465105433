import texturometro1 from '../texturometro/texturometro1.png'
import texturometro2 from '../texturometro/texturometro2.png'
import texturometro4 from '../texturometro/texturometro4.png'

const texturometroImages = [
    texturometro4,
    texturometro1,
    texturometro2,
]

export default texturometroImages