import tapdencity1 from '../tapdensity/tapdencity1.png'
import tapdencity2 from '../tapdensity/tapdencity2.png'
import tapdencity3 from '../tapdensity/tapdencity3.png'

const tapdencityImagenes = [
    tapdencity2,
    tapdencity1,
    tapdencity3,

]

export default tapdencityImagenes