import testhermeticidad1 from '../testhermeticidad/testhermeticidad1.png'
import testhermeticidad2 from '../testhermeticidad/testhermeticidad2.png'
import testhermeticidad3 from '../testhermeticidad/testhermeticidad3.png'
import testhermeticidad4 from '../testhermeticidad/testhermeticidad4.png'

const testhermeticidadImagenes = [
    testhermeticidad2,
    testhermeticidad1,
    testhermeticidad3,
    testhermeticidad4,

]

export default testhermeticidadImagenes