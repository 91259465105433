import tituladorautomatico1 from '../tituladorautomatico/tituladorautomatico1.png'
import tituladorautomatico2 from '../tituladorautomatico/tituladorautomatico2.png'
import tituladorautomatico3 from '../tituladorautomatico/tituladorautomatico3.png'
import tituladorautomatico4 from '../tituladorautomatico/tituladorautomatico4.png'

const tituladorautomaticoImagenes = [
    tituladorautomatico1,
    tituladorautomatico2,
    tituladorautomatico3,
    tituladorautomatico4,

]

export default tituladorautomaticoImagenes