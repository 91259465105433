import penetrometro1 from '../penetrometro/penetrometro1.png'
import penetrometro2 from '../penetrometro/penetrometro2.png'
import penetrometro3 from '../penetrometro/penetrometro3.png'


const imagepenetrometro = [
    penetrometro2,
    penetrometro1,
    penetrometro3
]

export default imagepenetrometro