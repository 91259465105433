import automuestreador1 from '../automuestreador/automuestreador1.jpg'
import automuestreador2 from '../automuestreador/automuestreador2.jpg'
import automuestreador3 from '../automuestreador/automuestreador3.jpg'
import automuestreador4 from '../automuestreador/automuestreador4.jpg'

const AutomuestreadorImages = [
    automuestreador4,
    automuestreador3,
    automuestreador1,
    automuestreador2,
]

export default AutomuestreadorImages